import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom';

export default function Home() {
  return (
    <>
      <header className='padding-left-right'>
        <div className='container-fluid mt-3' >
          <div className='row'>
            <div className='col-lg-6'>
              <div className='left-header-section'>
                <div className='logo'>
                  <img className='logo-img' src={require('../../assets/images/spiritual.png')} alt="" />
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='header-right-section'>
                <Link to={"https://www.facebook.com/mehandipurbalajidausa"}>
                  <div className='social-icon-box'>
                    <FontAwesomeIcon icon={faFacebook} />
                  </div>
                </Link>
                <Link to={"https://www.instagram.com/mehandipurbalajidausa/"}>
                  <div className='social-icon-box'>
                    <FontAwesomeIcon icon={faInstagram} />
                  </div>
                </Link>
                <Link to={"https://www.youtube.com/@ShriMehandipurBalajiapp"}>
                  <div className='social-icon-box'>
                    <FontAwesomeIcon icon={faYoutube} />
                  </div>
                </Link>
                <Link to={"https://chat.whatsapp.com/Kw3fa7hh518FdhMBwomXZM"}>
                  <div className='social-icon-box'>
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className='banner-section'>
        <div className='overlay'></div>
        <div className='banner-content padding-left-right '>
          <h1>Immerse Your Soul in Spiritual FM:</h1>
          <h4>Where Devotion Meets Digital, Unveiling Episodic Spiritual Journeys and Sacred E-books.</h4>
          <p>Available on Play Store and App Store, Download now :-</p>
          <div className='download-icon '>
            <div className='app-image'>
              <img className='store-img' src={require('../../assets/images/app_store.png')} alt="" />
            </div>
            <div className='app-image'>
              <img className='store-img-2' src={require('../../assets/images/get_it_on_google_play.png')} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className='app-store'>

      </section>
    </>
  )
}
